/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Drawer, Spin, Tooltip } from "antd";
import "../RiskReviewPendingDrawer/index.css";
import moment from "moment";
import { message } from "antd";
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import { baseUrl } from "../../../../services/services.profile";
import ManageConnection from "../ManageConnection";
import { useUserContext } from "../../../../contexts/userContext";
import {
  askAiAgentComment,
  getConnection,
  requestIdConnection,
  sendConnectionMail,
} from "../../../../services/connection";
import TranslateDrawer from "../AiViewerDrawer/translateDrawer/translateDrawer";
import AiViewerDrawer from "../AiViewerDrawer/aiViewerDrawer";
import { Level, Scope } from "../../../TeamMember/CheckBtn";
import ManageConnectionEditDrawer from "../ManageConnectionEditDrawer";
import AddRiskAssessmentReport from "../AddRiskAssessmentReport";
import ViewRiskAssessmentReport from "../ViewRiskAssessmentReport";
import { IoMdClose } from "react-icons/io";
import { getOnboardingGridData } from "../../../../services/customer-ai-viewer";
import RiskAssessementButton from "../AiViewerDrawer/RiskAssessmentButton";
import { marked } from "marked";
import RTE from "../../../rte";
import RiskAssessmentDetailsStep from "./riskAssessmentDetailsStep";
import { FiInfo } from "react-icons/fi";
import { toolTipsData } from "../../CommonData/ToolTipsData";

const riskLevelColors = { Low: "#70ad47", Medium: "#ffc000", High: "#ff0000" };

const riskColorLevels = {
  "bg-[#70ad47]": "Low",
  "bg-[#ffc000]": "Medium",
  "bg-[#ff0000]": "High",
};

const riskColorBgColor = {
  "bg-[#70ad47]": "#70ad47",
  "bg-[#ffc000]": "#ffc000",
  "bg-[#ff0000]": "#ff0000",
};

const riskLevel = [
  { name: "Low", color: riskLevelColors["Low"] },
  { name: "Medium", color: riskLevelColors["Medium"] },
  { name: "High", color: riskLevelColors["High"] },
];

const categories = [
  { label: "Territorial Risk", value: "Region" },
  { label: "Activity Risk", value: "Activity/Funds" },
  { label: "Sanctions Screening", value: "Sanctions Screening" },
  { label: "Directors/UBO's Screening", value: "Documents" },
  { label: "Website/Online Activity", value: "Adverse Media" },
  {
    label: "Overall Risk Classification",
    value: "Overall risk rating",
  },
];

// The value here should be matching with above categories array => value
const fieldMappings = {
  region: "Region",
  activityfunds: "Activity/Funds",
  sanctions_risk: "Sanctions Screening",
  document: "Documents",
  adversemedia: "Adverse Media",
  overall_risk: "Overall risk rating",
};

const NetworkRiskReviewPendingBusinessDrawer = ({
  isOpen,
  setIsOpen,
  networkDetail,
  getNetworks,
  networks,
  countryCode,
  networkFilter,
  filterList,
  adminDetails,
  deleteNetwork,
  networksLoadedData,
}) => {
  const { user, RoleManage, userData } = useUserContext();
  let [commentOpen, setCommentOpen] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [riskReports, setRiskReports] = useState([]);
  const [description, setDescription] = useState("");
  let [aiObservations, setAiObservations] = useState("");

  const [recommended, setRecommended] = useState({});
  const [riskSelectionChanged, setRiskSelectionChanged] = useState(false);
  const [crossGridDrawerOpen, setCrossGridDrawerOpen] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [manageConnectionDrawerOpen, setManageConnectionDrawerOpen] =
    useState(false);
  const [allowRiskAssessment, setAllowRiskAssessment] = useState(false);
  const [aIAssessmentLoading, setAIAssessmentLoading] = useState(false);
  const [memberDetailConnection, setMemberDetailConnection] = useState(null);
  const [isOpenTranslate, setIsOpenTranslate] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [riskAssessmentStepsLoading, setRiskAssessmentStepsLoading] =
    useState(false);
  const riskAssessmentDrawerRef = useRef(null);
  const [overAllRecommended, setOverAllRecommended] = useState("");
  const [selectedRiskLevel, setSelectedRiskLevel] = useState({});
  const [riskAssessmentDone, setRiskAssessmentDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [memberDetailConnectionData, setMemberDetailConnectionData] =
    useState();
  const [memberDetailOpen, setMemberDetailOpen] = useState(false);
  const [memberDetailHeader, setMemberDetailHeader] = useState("");
  const [askAiAgentLoading, setAskAiAgentLoading] = useState(false);
  const [aiCommentEdit, setAiCommentEdit] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);

  const [oldData, setOldData] = useState({
    selectedLevelValue: {},
    selectedRecommended: {},
    oldDescription: "",
  });
  const descriptionRef = useRef(null);
  const RTERef = useRef();

  const connectionTypeName = "network";

  const tokenId = networkDetail?.secret_token;

  const recieverId = networkDetail?.user_id?._id;
  const abortSignalRef = useRef(null);

  const sectionDisable =
    userData?._id !== "652943c69df2a7301b23c9ab" &&
    user !== RoleManage?.MLRO &&
    user !== RoleManage?.ADMIN &&
    (user !== RoleManage?.SECURITY_ROLE ||
      (user === RoleManage?.SECURITY_ROLE &&
        (!allowRiskAssessment || !userData?.allowRiskAssessment)));

  const [addReportDrawerOpen, setAddReportDrawerOpen] = useState(false);
  const addReportDrawerClose = () => {
    setAddReportDrawerOpen(false);
  };

  const [viewReportDrawerOpen, setViewReportDrawerOpen] = useState(false);
  const viewReportDrawerClose = () => {
    setViewReportDrawerOpen(false);
  };

  const manageConnectionDrawerClose = () => {
    setManageConnectionDrawerOpen(false);
  };

  const crossGridDrawerClose = () => {
    setCrossGridDrawerOpen(false);
  };

  const handleDownloadClick = (networkDetail) => {
    setIsMerging(true);

    // Call the mergeAndDownloadPDFs function when the button is clicked
    mergeAndDownloadPDFs(networkDetail);

    setIsMerging(false);
  };

  const mergeAndDownloadPDFs = async (networkDetail) => {
    let docArr = [];
    let CddPdf = "";
    let IRPdf = "";
    let UboPdf = "";
    let PolPdf = "";
    if (networkDetail?.user_id?.company) {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        "https://api.kyb.pro" +
          "/" +
          networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company.cert &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.company?.cert;
      PolPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.poa_le &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.company?.poa_le;
    } else {
      CddPdf =
        networkDetail?.user_id?.cdd?.fileP &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.cdd?.fileP;
      IRPdf =
        networkDetail?.user_id?.InspectRp?.fileUrl &&
        "https://api.kyb.pro" +
          "/" +
          networkDetail?.user_id?.InspectRp?.fileUrl;
      UboPdf =
        networkDetail?.user_id?.company &&
        networkDetail?.user_id?.company?.cert &&
        "https://api.kyb.pro" + "/" + networkDetail?.user_id?.company?.cert;
    }

    if (docList?.length > 0) {
      for (let i = 0; i < docList.length; i++) {
        const el = docList[i];
        docArr.push("https://api.kyb.pro" + "/" + el?.filename);
      }
    }
    try {
      // Create a new PDF document to hold the merged content
      const mergedPdf = await PDFDocument.create();
      let pdfUrls = [];

      // Assuming this is the URL to the PDF
      if (networkDetail?.user_id?.company) {
        let arr = [];
        arr = [CddPdf, IRPdf, UboPdf, PolPdf];
        pdfUrls = arr.concat(docArr);
      } else {
        let arr = [];
        arr = [CddPdf, IRPdf];
        pdfUrls = arr.concat(docArr);
      }

      for (const pdfUrl of pdfUrls) {
        // Fetch the PDF data from the URL (you can also use other methods to fetch the data)
        const response = await fetch(pdfUrl);
        const pdfData = new Uint8Array(await response.arrayBuffer());

        // Load the fetched PDF data into a PDF document
        const pdf = await PDFDocument.load(pdfData);

        // Copy pages from the fetched PDF to the merged PDF
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Serialize the merged PDF to a Uint8Array
      const mergedPdfBytes = await mergedPdf.save();

      // Create a Blob from the Uint8Array data
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(mergedPdfBlob);
      downloadLink.download = "SupportingDocuments.pdf";

      // Trigger a click event on the download link to initiate the download
      downloadLink.click();
    } catch (error) {
      console.error("Error merging and downloading PDFs:", error);
    }
  };

  const sortedArray = commentList.sort(function (a, b) {
    return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
  });

  // Set discard changes based on previously saved data
  const handleDiscard = () => {
    if (commentList.length !== 0) {
      setAiCommentEdit(false);
      setCommentOpen(true);
    } else {
      Object.keys(oldData["selectedLevelValue"]).length > 0
        ? setAiCommentEdit(true)
        : setAiCommentEdit(false);

      setDescription(oldData["oldDescription"]);
      if (RTERef?.current) {
        RTERef.current.setContent(oldData["oldDescription"]);
      }
    }
    showDiscard && setShowDiscard(false);
    setSelectedRiskLevel(oldData["selectedLevelValue"]);
    setRecommended(oldData["selectedRecommended"]);
    if (descriptionRef.current) {
      descriptionRef.current.value = "";
    }
  };

  const handleComment = async () => {
    setCommentOpen(!commentOpen);
    setDescription("");
    !showDiscard && setShowDiscard(true);
  };

  const getRecommendedValuesFromAIRiskTypeData = (riskTypeData) => {
    return Object.entries(riskTypeData || {}).reduce((acc, [key, value]) => {
      const newKey = fieldMappings[key];

      // If it's a valid BUSINESS type risk factors key then only add
      if (newKey) {
        acc[newKey] = value?.includes("Low")
          ? "Low"
          : value?.includes("Medium")
          ? "Medium"
          : "High";
      }
      return acc;
    }, {});
  };

  const getSelectedRiskLevelsFromAIRiskTypeData = (riskTypeData) => {
    const newMapping = Object.keys(riskTypeData)?.reduce((acc, key) => {
      const newKey = fieldMappings[key]; // (territorial_risk => "Territorial Risk")

      // If it's a valid BUSINESS type risk factors key then only add
      if (newKey) {
        const riskLevel = riskTypeData[key]?.includes("Low")
          ? "Low"
          : riskTypeData[key]?.includes("Medium")
          ? "Medium"
          : "High";
        acc[newKey] = {
          name: riskLevel,
          color: riskLevelColors[riskLevel],
        };
      }
      return acc;
    }, {});
    const overAllRecommended = getRecommendedByCurrentSelection(newMapping);

    if (overAllRecommended) {
      newMapping["Overall risk rating"] = {
        name: overAllRecommended,
        color: riskLevelColors[overAllRecommended],
      };
    }
    return newMapping;
  };

  useEffect(() => {
    setOverAllRecommended(getRecommendedByCurrentSelection(selectedRiskLevel));
  }, [selectedRiskLevel]);

  const getGridResponse = (
    showDefaultAIComment = false,
    commentListData = []
  ) => {
    if (!tokenId) {
      return;
    }

    setRiskAssessmentStepsLoading(true);

    getOnboardingGridData(tokenId)
      .then((res) => {
        if (res?.error) {
          setRiskAssessmentStepsLoading(false);
          return;
        }
        setAiObservations(res.gridData.user_edited["brief_risk_assessment"]);

        // Ai given risk_type object
        const riskTypeData = res?.gridData?.aiCommentData?.risk_type;

        let rData = {};

        const transformRiskTypes = (riskArray) => {
          const riskMapping = {
            region: "region_present",
            activityfunds: "activityfunds_present",
            sanctions_risk: "sanctions_screening_present",
            document: "document_present",
            adversemedia: "adversemedia_present",
            overall_risk: "overall_risk_present",
          };

          return riskArray.reduce((result, item) => {
            Object.entries(riskMapping).forEach(([key, mappingKey]) => {
              if (item[mappingKey]) {
                result[key] = `${item[mappingKey].split(" ")[0]} Risk`;
              }
            });
            return result;
          }, {});
        };

        const found = commentListData?.find(
          (item) => item?.ai_comment && item?.risk_types
        );

        rData = found ? transformRiskTypes(found.risk_types) : {};

        const recommendedData = getRecommendedValuesFromAIRiskTypeData(
          Object.keys(rData).length > 0 ? rData : riskTypeData
        );
        setRecommended(recommendedData);

        // Update the state with AI grid response for discarding functionality
        setOldData((prevOldData) => ({
          ...prevOldData,
          selectedRecommended: recommendedData,
        }));

        if (showDefaultAIComment && riskTypeData) {
          const htmlContent = marked(
            res?.gridData?.aiCommentData?.description,
            {
              gfm: true,
              breaks: true,
            }
          );
          setDescription(htmlContent?.replace(/(<a [^>]*>)(?! )/g, "$1 "));
          setAiCommentEdit(true);
          setCommentOpen(false);

          setSelectedRiskLevel(
            // will get the required risk factor name, color object
            getSelectedRiskLevelsFromAIRiskTypeData(riskTypeData)
          );
          // Update the state with AI grid response for discarding functionality
          setOldData((prevOldData) => ({
            ...prevOldData,
            selectedLevelValue:
              getSelectedRiskLevelsFromAIRiskTypeData(riskTypeData),
            oldDescription: htmlContent?.replace(/(<a [^>]*>)(?! )/g, "$1 "),
          }));
        }
      })
      .finally(() => {
        setRiskAssessmentStepsLoading(false);
      });
  };

  const isEditorEmpty = (editorContent) => {
    const cleanContent = editorContent.replace(/<[^>]+>/g, "").trim();
    return cleanContent === "";
  };

  const addComment = async () => {
    const arr = [
      "Region",
      "Activity/Funds",
      "Sanctions Screening",
      "Documents",
      "Adverse Media",
      "Overall risk rating",
    ];

    let arr1 = [];

    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (selectedRiskLevel[item]) {
        arr1.push(item);
      }
    }
    if (arr1.length !== 6) {
      message.error(
        "Please select the levels of risk for Activity Risk, Sanctions Screening, Territorial Risk, Website/Online Activity, Directors/UBO's Screening and Overall Risk Classification!"
      );
    } else if (
      aiCommentEdit
        ? isEditorEmpty(RTERef?.current?.content)
        : description === ""
    ) {
      message.error("Please Add Comments!");
    } else {
      setCommentLoading(true);
      const res = await axios
        .post(
          baseUrl + "/api/network/networkAddRisk",
          generateRiskReviewPayload()
        )
        .then((res) => {
          setDescription("");
          setCommentOpen(true);
          setAiCommentEdit(false);
          setShowDiscard(false);

          // Update the state with save comments for discarding functionality
          setOldData((prevOldData) => ({
            ...prevOldData,
            selectedLevelValue: selectedRiskLevel,
          }));

          if (!riskSelectionChanged) {
            message.success("Comment was successfully added");
          } else {
            message.success(res?.data?.message);
          }
          if (filterList.length > 0) {
            networkFilter();
          } else {
            getNetworks();
          }
        })
        .catch((error) => {
          message.error(res?.data?.error);
        })
        .finally(() => {
          setCommentLoading(false);
        });
    }
  };

  // set the comment list and initialze the data
  const handleCommentList = async () => {
    const selectedRiskLevelObj = {};
    for (let i = 0; i < networks?.length; i++) {
      const el = networks[i];
      if (el?._id === networkDetail?._id) {
        setCommentList(el?.comment_list);
        setDocList(el && el?.document_list);
        const riskReports = (
          el && el?.risk_reports ? [...el?.risk_reports] : []
        ).reverse();

        setRiskReports(riskReports);

        if (el?.risk_rating_list.length !== 0) {
          for (let i = 0; i < el?.risk_rating_list.length; i++) {
            const item = el?.risk_rating_list[i];

            selectedRiskLevelObj[item?.name] = {
              color: riskColorBgColor[item?.color],
              name: riskColorLevels[item?.color],
            };
          }
        }

        if (el?.overrall_Risk) {
          selectedRiskLevelObj["Overall risk rating"] = {
            name: el?.overrall_Risk,
            color: riskLevelColors[el?.overrall_Risk],
          };
          setRiskAssessmentDone(true);
        }
        // Update the state with risk rating list for discarding functionality
        setOldData((prevOldData) => ({
          ...prevOldData,
          selectedLevelValue: selectedRiskLevelObj,
        }));

        setSelectedRiskLevel(selectedRiskLevelObj);

        // Make a grid data call once initialized the comments and pass if to show default comment
        getGridResponse(!el?.overrall_Risk, el?.comment_list);

        if (el?.commentList !== 0) {
          setCommentOpen(true);
        }
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    handleCommentList();
  }, [networks]);

  // Allow Risk Assessment
  useEffect(() => {
    if (user === RoleManage.SECURITY_ROLE) {
      getConnection(networkDetail?._id).then((res) => {
        if (!res?.error) {
          const network = res?.data;
          setMemberDetailConnection(network);
          const getLoginDepartment = userData.department.map(
            (department) => department?._id
          );
          const getDepartment = res?.data.department.find((department) =>
            getLoginDepartment.includes(department?._id)
          );
          setAllowRiskAssessment(getDepartment);
        }
        //  setIsLoading(false);
      });
    }
  }, [isOpen]);

  let riskAssessmentSteps = [
    {
      key: 1,
      label: "Create Cross Reference Grid",
      buttonName: aiObservations ? "View" : "Create",
      loadingName: riskAssessmentStepsLoading,
      click: () => setCrossGridDrawerOpen(true),
    },
    {
      key: 2,
      label: "Indicate Risk Level and Comment",
      loadingName: false,
      buttonName: riskAssessmentDone ? "Update" : "Pending",
      info: true,
    },
    {
      key: 3,
      label: "Create Risk Assessment Report",
      loadingName: aIAssessmentLoading,
      buttonName: (
        <RiskAssessementButton
          setIsOpenTranslate={setIsOpenTranslate}
          ref={riskAssessmentDrawerRef}
          type="network"
          apiId={networkDetail?._id}
          fetchConnectionData={getNetworks}
          setAIAssessmentLoading={setAIAssessmentLoading}
          riskLevel={riskAssessmentDone}
          aiObservations={aiObservations}
        />
      ),
    },
  ];

  const [connectionActions, setConnectionActions] = useState([
    {
      id: 1,
      fieldName1: "Department",
      fieldDetail1: "",
    },
    {
      id: 2,
      fieldName1: "Scope",
      fieldDetail1: <Scope value={"In-scope"} />,
    },
  ]);

  const MemberEnum = {
    MemberRole: "Edit Role",
    MemberDepartment: "Edit Department",
    MemberScope: "Edit Scope",
    MemberAccountType: "Edit Account Type",
  };

  const setData = (connection) => {
    if (connection) {
      setMemberDetailConnectionData(connection);

      setConnectionActions([
        {
          id: 1,
          fieldName1: "Department",
          fieldDetail1:
            connection?.department && connection?.department?.length > 0
              ? connection?.department
                  .map((res) => res?.department)
                  .join(", ")
                  .toString()
              : "",
        },
        {
          id: 2,
          fieldName1: "Scope",
          fieldDetail1: connection ? (
            connection?.inScope ? (
              <Scope value={"In-scope"} />
            ) : (
              <Level value={"Out-of-scope"} />
            )
          ) : (
            ""
          ),
        },
      ]);
    } else {
      setConnectionActions([
        {
          id: 1,
          fieldName1: "Department",
          fieldDetail1: "",
        },
        {
          id: 2,
          fieldName1: "Scope",
          fieldDetail1: <Scope value={"In-scope"} />,
        },
      ]);
    }
  };
  const memberDetailClose = () => {
    setMemberDetailOpen(false);
  };

  const getSingleConnection = () => {
    setIsLoading(true);
    getConnection(networkDetail?._id).then((res) => {
      if (!res?.error) {
        const connection = res?.data;
        setData(connection);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (!memberDetailConnectionData) {
      getSingleConnection();
    } else {
      setData(memberDetailConnectionData);
      setIsLoading(false);
    }
  }, [networkDetail]);

  const handleAction = (data) => {
    setMemberDetailOpen(true);
    if (data?.fieldName1 === "Scope") {
      setMemberDetailHeader(MemberEnum?.MemberScope);
    } else {
      setMemberDetailHeader(MemberEnum?.MemberDepartment);
    }
  };

  const sendMail = () => {
    if (recieverId) {
      const payload = { receiverId: recieverId };
      sendConnectionMail(payload).then((res) => {
        if (res?.error) {
          message.error("Expired doc request fail!");
        } else {
          message.success("Expired doc request successfully!");
        }
      });
    } else {
      message.success("Can't request doc!");
    }
  };

  const requestIdApi = () => {
    requestIdConnection({
      userId: recieverId,
    })
      .then((res) => {
        if (res?.error) {
          throw res?.error;
        }
        message.success("Request to update an ID document sent successfully");
      })
      .catch(() => {
        message.error(
          "Something went wrong sending the request, please try again later"
        );
      });
  };

  const generateRiskReviewPayload = () => {
    const riskRatingList = [];

    categories?.forEach((category) => {
      // Don't include Overall Risk Classification in risk rating list
      if (category?.value !== "Overall risk rating") {
        riskRatingList.push({
          name: category?.value,
          color: selectedRiskLevel[category?.value]
            ? `bg-[${selectedRiskLevel[category?.value].color}]`
            : "",
        });
      }
    });

    // Create risk Type object
    let riskType = {
      sanctions_risk: "",
      adversemedia: "",
      activityfunds: "",
      region: "",
      overall_risk: "",
      document: "",
      addedby: userData.fname + " " + userData.lname,
    };

    for (let i = 0; i < riskRatingList.length; i++) {
      const el = riskRatingList[i];
      if (el.name === "Adverse Media") {
        riskType.adversemedia =
          el?.color === "bg-[#70ad47]"
            ? "Low risk"
            : el?.color === "bg-[#ffc000]"
            ? "Medium risk"
            : el?.color === "bg-[#ff0000]"
            ? "High risk"
            : "";
      }
      if (el.name === "Region") {
        riskType.region =
          el?.color === "bg-[#70ad47]"
            ? "Low risk"
            : el?.color === "bg-[#ffc000]"
            ? "Medium risk"
            : el?.color === "bg-[#ff0000]"
            ? "High risk"
            : "";
      }
      if (el.name === "Activity/Funds") {
        riskType.activityfunds =
          el?.color === "bg-[#70ad47]"
            ? "Low risk"
            : el?.color === "bg-[#ffc000]"
            ? "Medium risk"
            : el?.color === "bg-[#ff0000]"
            ? "High risk"
            : "";
      }
      if (el.name === "Sanctions Screening") {
        riskType.sanctions_risk =
          el?.color === "bg-[#70ad47]"
            ? "Low risk"
            : el?.color === "bg-[#ffc000]"
            ? "Medium risk"
            : el?.color === "bg-[#ff0000]"
            ? "High risk"
            : "";
      }
      if (el.name === "Documents") {
        riskType.document =
          el?.color === "bg-[#70ad47]"
            ? "Low risk"
            : el?.color === "bg-[#ffc000]"
            ? "Medium risk"
            : el?.color === "bg-[#ff0000]"
            ? "High risk"
            : "";
      }
    }
    riskType.overall_risk = selectedRiskLevel["Overall risk rating"]
      ? selectedRiskLevel["Overall risk rating"]?.name
      : "";
    return {
      description: aiCommentEdit ? RTERef?.current?.content : description,
      risk: "Low", // Passing this as we don't have option 1 we had in new UI
      network_id: networkDetail?._id,
      risk_rating_list: riskRatingList,
      risk_type: riskType,
      overrall_Risk: selectedRiskLevel["Overall risk rating"]
        ? selectedRiskLevel["Overall risk rating"]?.name
        : "",
      ai_comment: aiCommentEdit,
      utype: "BUSINESS",
    };
  };

  const handleButtonClick = (categoryName, risk) => {
    !showDiscard && setShowDiscard(true);
    aiCommentEdit && setDescription("");
    setAiCommentEdit(false);
    setCommentOpen(false);
    setRiskSelectionChanged(true);

    setSelectedRiskLevel((prev) => {
      let updatedRisk = { ...prev };

      updatedRisk[categoryName] = {
        ...risk,
      };

      if (categoryName !== "Overall risk rating") {
        const newRecommend = getRecommendedByCurrentSelection(updatedRisk);
        setOverAllRecommended(newRecommend);

        updatedRisk["Overall risk rating"] = {
          name: newRecommend,
          color: riskLevelColors[newRecommend],
        };
      }
      return updatedRisk;
    });
  };

  const getRecommendedByCurrentSelection = (updatedRisk) => {
    const relevantCategoriesValues = [
      "Adverse Media",
      "Activity/Funds",
      "Region",
      "Documents",
      "Sanctions Screening",
    ];
    const riskCounts = { High: 0, Medium: 0, Low: 0 };

    relevantCategoriesValues.forEach((cat) => {
      if (updatedRisk[cat]) {
        riskCounts[updatedRisk[cat].name]++;
      }
    });

    // Update the Overall risk rating based on the counts of risks
    let newRecommend = "";

    if (riskCounts.High > 0) {
      newRecommend = "High";
    } else if (riskCounts.Medium >= 3) {
      newRecommend = "High";
    } else if (riskCounts.Medium >= 2) {
      newRecommend = "Medium";
    } else if (riskCounts.Medium === 1) {
      newRecommend = "Low";
    } else if (riskCounts.Low >= 1) {
      newRecommend = "Low";
    } else {
      newRecommend = "";
    }

    return newRecommend;
  };

  useEffect(() => {
    return () => {
      abortSignalRef.current?.abort();
    };
  }, []);

  const askAiAgentData = () => {
    abortSignalRef.current = new AbortController();
    const abortSignal = abortSignalRef.current.signal;

    setAskAiAgentLoading(true);
    askAiAgentComment(
      {
        userId: recieverId,
      },
      abortSignal
    )
      .then((res) => {
        if (res?.error) {
          return message.error(res?.error);
        }
        message.success("Comments get successfully from ai agents");
        setShowDiscard(true);
        const htmlContent = marked(res?.aiCommentData?.description, {
          gfm: true,
          breaks: true,
        });
        setDescription(htmlContent?.replace(/(<a [^>]*>)(?! )/g, "$1 "));

        setAiCommentEdit(true);
        setCommentOpen(false);

        const riskTypeData = res?.aiCommentData?.risk_type;

        setRecommended(
          // will get the required risk factor name, level object
          getRecommendedValuesFromAIRiskTypeData(riskTypeData)
        );

        setSelectedRiskLevel(
          // will get the required risk factor name, color object
          getSelectedRiskLevelsFromAIRiskTypeData(riskTypeData)
        );
      })
      .catch(() => {
        return;
        // message.error(
        //   "An error occurred while retrieving AI agent comments. Please try again later."
        // );
      })

      .finally(() => {
        setAskAiAgentLoading(false);
      });
  };

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">Manage Customer Risk Assessment</p>
          </div>
        }
        className="riskAssessmentDrawer"
        closable={false}
        onClose={handleClose}
        open={isOpen}
        footer={
          <div className="flex justify-end items-center gap-3 !bg-transparent lg:w-1/2 lg:ml-auto">
            <Button
              type="button"
              disabled={sectionDisable || networksLoadedData}
              className="flex justify-center text-xs !border-primary border-2 font-semibold !text-white h-8 !bg-primary px-5 !rounded-lg mr-auto"
              onClick={() => {
                !aiObservations
                  ? message.error(
                      "Please complete the Step 1 first for better results"
                    )
                  : askAiAgentData();
              }}
              loading={askAiAgentLoading}
            >
              Risk Assessment Checks
            </Button>

            {showDiscard && (
              <Button
                disabled={
                  sectionDisable || networksLoadedData || askAiAgentLoading
                }
                type="button"
                className="flex justify-center text-xs disabled:opacity-80 !border-primary border-2 font-semibold !text-primary h-8 !bg-transparent px-5 !rounded-lg"
                onClick={handleDiscard}
              >
                Discard
              </Button>
            )}

            {!!commentOpen && commentList?.length !== 0 ? (
              <button
                type="button"
                disabled={
                  sectionDisable || networksLoadedData || askAiAgentLoading
                }
                className="text-xs disabled:opacity-80 border-primary whitespace-nowrap border-2 font-semibold tracking-wide text-white bg-primary w-32 h-8 px-2 rounded-lg"
                onClick={handleComment}
              >
                Add Comment
              </button>
            ) : (
              <Button
                type="button"
                disabled={
                  sectionDisable || networksLoadedData || askAiAgentLoading
                }
                className="flex justify-center disabled:opacity-80 text-xs !border-primary border-2 font-semibold !text-white w-32 h-8 !bg-primary px-2 !rounded-lg"
                onClick={addComment}
                loading={commentLoading}
              >
                Save
              </Button>
            )}
          </div>
        }
      >
        <>
          <div className="flex justify-end absolute top-2 left-0 right-0 pr-2">
            <IoMdClose
              onClick={handleClose}
              size={24}
              className="text-gray-500 cursor-pointer hover:text-primary flex justify-center items-center"
            />
          </div>

          <Spin
            spinning={networksLoadedData}
            wrapperClassName={networksLoadedData && "custom-spin-wrapper"}
          >
            <div className="flex flex-col w-full py-3 px-2">
              {networkDetail?.user_id?.removeOn && (
                <Alert
                  className="mb-4"
                  message="Informational Notes"
                  description={
                    <div>
                      <p>
                        We want to inform you that this connection has requested
                        the deletion of their account. All associated
                        information will be permanently removed on{" "}
                        {moment
                          .unix(networkDetail?.user_id?.removeOn / 1000)
                          .format("DD MMM YYYY")}
                        .
                      </p>
                      <br />
                      <p>
                        To comply with AML regulations, please retain any
                        necessary KYC/KYB documents if you have engaged in
                        transactions with this connection. Ensure you download
                        all the Inspection Reports, Risk Assessment Report, and
                        Supporting Documents before{" "}
                        {moment
                          .unix(networkDetail?.user_id?.removeOn / 1000)
                          .format("DD MMM YYYY")}{" "}
                        and store them securely.
                      </p>
                      <br />
                      <p>
                        For any questions, please contact our support team at
                        support@juste.ai.
                      </p>
                    </div>
                  }
                  type="info"
                  showIcon
                />
              )}

              <div className="relative h-[98%] grid lg:grid-cols-2 grid-cols-1 w-full lg:flex-row gap-5 flex-1">
                <RiskAssessmentDetailsStep
                  networkDetail={networkDetail}
                  deleteNetwork={deleteNetwork}
                  countryCode={countryCode}
                  isLoading={isLoading}
                  connectionActions={connectionActions}
                  handleAction={handleAction}
                  requestIdApi={requestIdApi}
                  sendMail={sendMail}
                  riskAssessmentSteps={riskAssessmentSteps}
                  setViewReportDrawerOpen={setViewReportDrawerOpen}
                  setAddReportDrawerOpen={setAddReportDrawerOpen}
                />

                <div className="flex flex-col gap-5 w-full pb-3">
                  {/* comment section */}
                  {categories?.map((category, index) => (
                    <div
                      key={index}
                      className="bg-[#fff] px-5 py-3 w-full flex sm:flex-row flex-col sm:justify-between sm:gap-5 ms:gap-3 gap-2 rounded-xl shadow-md sm:items-center"
                    >
                      <p className="text-[#000] font-bold flex gap-2 items-center text-[15px] w-full">
                        {category?.label}
                        <span className="text-[#00000096]">
                          <Tooltip
                            color="black"
                            placement="right"
                            overlayStyle={{
                              maxWidth: "400px",
                              fontWeight: "400",
                            }}
                            title={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: toolTipsData[category?.value],
                                }}
                              />
                            }
                          >
                            <FiInfo />
                          </Tooltip>
                        </span>
                      </p>
                      <div className="flex flex-col gap-1">
                        <div className="flex flex-col gap-2 w-full">
                          <div className="flex xl:gap-1.5 lg:gap-1 gap-2 w-full">
                            {riskLevel?.map((item, ind) => (
                              <button
                                key={ind}
                                type="button"
                                onClick={() =>
                                  handleButtonClick(category?.value, item)
                                }
                                className={`text-xs whitespace-nowrap font-semibold tracking-wide border border-[${
                                  item?.color
                                }] xl:w-20 lg:w-16 sm:w-24 w-full h-6 px-2 rounded ${
                                  selectedRiskLevel[category?.value]?.name ===
                                  item.name
                                    ? `bg-[${item?.color}] text-white`
                                    : `bg-white text-[${item?.color}]`
                                }`}
                              >
                                {item?.name}
                              </button>
                            ))}
                          </div>
                          {recommended &&
                            category?.value !== "Overall risk rating" &&
                            Object.keys(recommended).length > 0 && (
                              <div
                                className={`flex ${
                                  recommended[category?.value] === "High"
                                    ? "justify-end"
                                    : recommended[category?.value] === "Medium"
                                    ? "justify-center"
                                    : recommended[category?.value] === "Low"
                                    ? "justify-start"
                                    : "hidden"
                                }`}
                              >
                                <div className="text-xs text-[#000] font-medium leading-none">
                                  Recommended
                                </div>
                              </div>
                            )}

                          {recommended &&
                            category?.value === "Overall risk rating" && (
                              <div
                                className={`flex ${
                                  overAllRecommended === "High"
                                    ? "justify-end"
                                    : overAllRecommended === "Medium"
                                    ? "justify-center"
                                    : overAllRecommended === "Low"
                                    ? "justify-start"
                                    : "hidden"
                                }`}
                              >
                                <div className="text-xs text-[#000] font-medium leading-none">
                                  Recommended
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* comment section */}
                  <div className="bg-[#fff] p-5 w-full rounded-xl shadow-md">
                    {commentOpen === false && aiCommentEdit ? (
                      <RTE
                        innerHTML={description}
                        ref={RTERef}
                        height="288px"
                        placeholder="Write your comments here..."
                        setShowDiscard={setShowDiscard}
                      ></RTE>
                    ) : commentOpen === true &&
                      !aiCommentEdit &&
                      commentList?.length !== 0 ? (
                      <div className="p-2.5 w-full h-[288px] text-sm overflow-auto text-gray-900 !bg-gray-50 rounded-xl !border-[2px] !border-gray-300">
                        {sortedArray?.map((item, index) => (
                          <div className="mb-5" key={index}>
                            <p className="font-bold text-[14px]">
                              {sortedArray?.length === index + 1
                                ? "Added"
                                : "Updated"}{" "}
                              on:{" "}
                              {moment
                                .unix(item?.createdAt / 1000)
                                .format("DD MMM YYYY")}
                            </p>

                            {item?.risk_types[6]?.addedby_present && (
                              <div className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                                Added By:{" "}
                                {item?.risk_types[6]?.addedby_present && (
                                  <p className="text-[13px]">
                                    <span className={"font-semibold"}>
                                      {" "}
                                      {
                                        item?.risk_types[6]?.addedby_present
                                      }{" "}
                                    </span>{" "}
                                  </p>
                                )}
                              </div>
                            )}

                            <div className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Territorial Risk:
                              {item?.risk_types[3]?.region_prev !== "" &&
                                item?.risk_types[3]?.region_present !==
                                  item?.risk_types[3]?.region_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[3]?.region_prev ===
                                        "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[3]?.region_prev ===
                                            "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[3]?.region_prev ===
                                            "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {item?.risk_types[3]?.region_prev}{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[3]?.region_prev ===
                                        "Medium risk" &&
                                      item?.risk_types[3]?.region_present ===
                                        "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[3]?.region_prev ===
                                            "High risk" &&
                                          item?.risk_types[3]
                                            ?.region_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[3]?.region_prev ===
                                            "High risk" &&
                                          item?.risk_types[3]
                                            ?.region_present === "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[3]?.region_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[3]?.region_present ===
                                      "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[3]?.region_present ===
                                      "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[3]?.region_present}{" "}
                              </span>
                            </div>

                            <div className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              {" "}
                              Activity Risk:
                              {item?.risk_types[2]?.activityfunds_prev !== "" &&
                                item?.risk_types[2]?.activityfunds_present !==
                                  item?.risk_types[2]?.activityfunds_prev && (
                                  <p className="text-[13px]">
                                    <span
                                      className={`${
                                        item?.risk_types[2]
                                          ?.activityfunds_prev === "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[2]
                                              ?.activityfunds_prev ===
                                            "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[2]
                                              ?.activityfunds_prev ===
                                            "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {
                                        item?.risk_types[2]?.activityfunds_prev
                                      }{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[2]
                                        ?.activityfunds_prev ===
                                        "Medium risk" &&
                                      item?.risk_types[2]
                                        ?.activityfunds_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[2]
                                            ?.activityfunds_prev ===
                                            "High risk" &&
                                          item?.risk_types[2]
                                            ?.activityfunds_present ===
                                            "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[2]
                                            ?.activityfunds_prev ===
                                            "High risk" &&
                                          item?.risk_types[2]
                                            ?.activityfunds_present ===
                                            "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[2]?.activityfunds_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[2]
                                        ?.activityfunds_present ===
                                      "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[2]
                                        ?.activityfunds_present === "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {
                                  item?.risk_types[2]?.activityfunds_present
                                }{" "}
                              </span>
                            </div>

                            {"sanctions_screening_present" in
                              (item?.risk_types?.[0] || {}) && (
                              <div className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                                Sanctions Screening:
                                {item?.risk_types[0]
                                  ?.sanctions_screening_prev !== "" &&
                                  item?.risk_types[0]
                                    ?.sanctions_screening_present !==
                                    item?.risk_types[0]
                                      ?.sanctions_screening_prev && (
                                    <p className="text-[13px]">
                                      {" "}
                                      <span
                                        className={`${
                                          item?.risk_types[0]
                                            ?.sanctions_screening_prev ===
                                          "Low risk"
                                            ? "text-[#70ad47]"
                                            : item?.risk_types[0]
                                                ?.sanctions_screening_prev ===
                                              "Medium risk"
                                            ? "text-[#ffc000]"
                                            : item?.risk_types[0]
                                                ?.sanctions_screening_prev ===
                                              "High risk"
                                            ? "text-[#ff0000]"
                                            : ""
                                        } font-semibold`}
                                      >
                                        {" "}
                                        {
                                          item?.risk_types[0]
                                            ?.sanctions_screening_prev
                                        }{" "}
                                      </span>
                                      <span>
                                        {item?.risk_types[0]
                                          ?.sanctions_screening_prev ===
                                          "Medium risk" &&
                                        item?.risk_types[0]
                                          ?.sanctions_screening_present ===
                                          "Low risk"
                                          ? "decreased"
                                          : item?.risk_types[0]
                                              ?.sanctions_screening_prev ===
                                              "High risk" &&
                                            item?.risk_types[0]
                                              ?.sanctions_screening_present ===
                                              "Low risk"
                                          ? "decreased"
                                          : item?.risk_types[0]
                                              ?.sanctions_screening_prev ===
                                              "High risk" &&
                                            item?.risk_types[0]
                                              ?.sanctions_screening_present ===
                                              "Medium risk"
                                          ? "decreased"
                                          : " increased"}{" "}
                                        to
                                      </span>{" "}
                                    </p>
                                  )}
                                <span
                                  className={`${
                                    item?.risk_types[0]
                                      ?.sanctions_screening_present ===
                                    "Low risk"
                                      ? "text-[#70ad47]"
                                      : item?.risk_types[0]
                                          ?.sanctions_screening_present ===
                                        "Medium risk"
                                      ? "text-[#ffc000]"
                                      : item?.risk_types[0]
                                          ?.sanctions_screening_present ===
                                        "High risk"
                                      ? "text-[#ff0000]"
                                      : ""
                                  } font-semibold`}
                                >
                                  {" "}
                                  {
                                    item?.risk_types[0]
                                      ?.sanctions_screening_present
                                  }{" "}
                                </span>
                              </div>
                            )}

                            <div className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Directors/UBO's Screening:
                              {item?.risk_types[5]?.document_prev !== "" &&
                                item?.risk_types[5]?.document_present !==
                                  item?.risk_types[5]?.document_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[5]?.document_prev ===
                                        "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[5]
                                              ?.document_prev === "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[5]
                                              ?.document_prev === "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {item?.risk_types[5]?.document_prev}{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[5]?.document_prev ===
                                        "Medium risk" &&
                                      item?.risk_types[5]?.document_present ===
                                        "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[5]?.document_prev ===
                                            "High risk" &&
                                          item?.risk_types[5]
                                            ?.document_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[5]?.document_prev ===
                                            "High risk" &&
                                          item?.risk_types[5]
                                            ?.document_present === "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[5]?.document_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[5]?.document_present ===
                                      "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[5]?.document_present ===
                                      "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[5]?.document_present}{" "}
                              </span>
                            </div>

                            <div className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Website/Online Activity:
                              {item?.risk_types[1]?.adversemedia_prev !== "" &&
                                item?.risk_types[1]?.adversemedia_present !==
                                  item?.risk_types[1]?.adversemedia_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[1]
                                          ?.adversemedia_prev === "Low risk"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[1]
                                              ?.adversemedia_prev ===
                                            "Medium risk"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[1]
                                              ?.adversemedia_prev ===
                                            "High risk"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {
                                        item?.risk_types[1]?.adversemedia_prev
                                      }{" "}
                                    </span>
                                    <span>
                                      {item?.risk_types[1]
                                        ?.adversemedia_prev === "Medium risk" &&
                                      item?.risk_types[1]
                                        ?.adversemedia_present === "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[1]
                                            ?.adversemedia_prev ===
                                            "High risk" &&
                                          item?.risk_types[1]
                                            ?.adversemedia_present ===
                                            "Low risk"
                                        ? "decreased"
                                        : item?.risk_types[1]
                                            ?.adversemedia_prev ===
                                            "High risk" &&
                                          item?.risk_types[1]
                                            ?.adversemedia_present ===
                                            "Medium risk"
                                        ? "decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[1]?.adversemedia_present ===
                                  "Low risk"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[1]
                                        ?.adversemedia_present === "Medium risk"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[1]
                                        ?.adversemedia_present === "High risk"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[1]?.adversemedia_present}{" "}
                              </span>
                            </div>

                            <div className="text-[13px] text-[#000810] font-normal pt-[3px] flex gap-1">
                              Overall Risk Classification:
                              {item?.risk_types[4]?.overall_risk_prev !== "" &&
                                item?.risk_types[4]?.overall_risk_present !==
                                  item?.risk_types[4]?.overall_risk_prev && (
                                  <p className="text-[13px]">
                                    {" "}
                                    <span
                                      className={`${
                                        item?.risk_types[4]
                                          ?.overall_risk_prev === "Low"
                                          ? "text-[#70ad47]"
                                          : item?.risk_types[4]
                                              ?.overall_risk_prev === "Medium"
                                          ? "text-[#ffc000]"
                                          : item?.risk_types[4]
                                              ?.overall_risk_prev === "High"
                                          ? "text-[#ff0000]"
                                          : ""
                                      } font-semibold`}
                                    >
                                      {" "}
                                      {
                                        item?.risk_types[4]?.overall_risk_prev
                                      }{" "}
                                      risk
                                    </span>
                                    <span>
                                      {item?.risk_types[4]
                                        ?.overall_risk_prev === "Medium" &&
                                      item?.risk_types[4]
                                        ?.overall_risk_present === "Low"
                                        ? " decreased"
                                        : item?.risk_types[4]
                                            ?.overall_risk_prev === "High" &&
                                          item?.risk_types[4]
                                            ?.overall_risk_present === "Low"
                                        ? " decreased"
                                        : item?.risk_types[4]
                                            ?.overall_risk_prev === "High" &&
                                          item?.risk_types[4]
                                            ?.overall_risk_present === "Medium"
                                        ? " decreased"
                                        : " increased"}{" "}
                                      to
                                    </span>{" "}
                                  </p>
                                )}
                              <span
                                className={`${
                                  item?.risk_types[4]?.overall_risk_present ===
                                  "Low"
                                    ? "text-[#70ad47]"
                                    : item?.risk_types[4]
                                        ?.overall_risk_present === "Medium"
                                    ? "text-[#ffc000]"
                                    : item?.risk_types[4]
                                        ?.overall_risk_present === "High"
                                    ? "text-[#ff0000]"
                                    : ""
                                } font-semibold`}
                              >
                                {" "}
                                {item?.risk_types[4]?.overall_risk_present} risk
                              </span>
                            </div>

                            <p className="text-[14px] font-bold">Comments:</p>
                            <div className="text-[#000810] !m-0 text-[14px] font-normal tracking-wide break-words customEditor">
                              {item?.ai_comment ? (
                                <RTE
                                  innerHTML={item?.description?.replace(
                                    /(<a [^>]*>)(?! )/g,
                                    "$1 "
                                  )}
                                  isDisabled={true}
                                  showToolbar={false}
                                ></RTE>
                              ) : (
                                item?.description
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <textarea
                          id="description"
                          name="description"
                          ref={descriptionRef}
                          className="p-2.5 w-full h-72 text-sm text-gray-900 !bg-gray-50 rounded-xl border !border-gray-300 outline-none focus:outline-none"
                          placeholder="Write your comments here..."
                          onChange={(e) => {
                            setDescription(e?.target?.value);
                            !showDiscard && setShowDiscard(true);
                          }}
                        ></textarea>
                      </div>
                    )}
                  </div>
                </div>
                {sectionDisable && (
                  <div className="absolute w-full h-full top-0 hover:cursor-not-allowed"></div>
                )}
              </div>
            </div>
          </Spin>
        </>
      </Drawer>
      {manageConnectionDrawerOpen && (
        <ManageConnection
          manageConnectionDrawerOpen={manageConnectionDrawerOpen}
          manageConnectionDrawerClose={manageConnectionDrawerClose}
          manageConnection={networkDetail}
          connectionType="network"
          adminDetails={adminDetails}
          getData={memberDetailConnection}
          fetchConnectionData={getNetworks}
        />
      )}

      {memberDetailOpen && (
        <ManageConnectionEditDrawer
          memberDetailOpen={memberDetailOpen}
          memberDetailClose={memberDetailClose}
          memberDetailHeader={memberDetailHeader}
          manageConnection={networkDetail}
          memberDetailConnection={memberDetailConnectionData}
          getSingleConnection={getSingleConnection}
          fetchConnectionData={getNetworks}
        />
      )}

      {crossGridDrawerOpen && (
        <AiViewerDrawer
          aiViewerDrawerOpen={crossGridDrawerOpen}
          aiViewerDrawerClose={crossGridDrawerClose}
          companyName={networkDetail?.user_id?.company?.name}
          tokenId={networkDetail?.secret_token}
          type={"network"}
          apiId={networkDetail?._id}
          fetchConnectionData={getNetworks}
        />
      )}
      {isOpenTranslate && (
        <TranslateDrawer
          isOpenTranslate={isOpenTranslate}
          handleClose={() => setIsOpenTranslate(false)}
          translateName={"Risk Assessment Report"}
          createAiRiskAssessment={
            riskAssessmentDrawerRef.current.createAiRiskAssessment
          }
          tokenId={networkDetail?.secret_token}
        />
      )}

      {addReportDrawerOpen && (
        <AddRiskAssessmentReport
          reportDrawerClose={addReportDrawerClose}
          reportDrawerOpen={addReportDrawerOpen}
          dataDetail={networkDetail}
          filterList={filterList}
          dataFilter={networkFilter}
          getData={getNetworks}
          type={connectionTypeName}
        />
      )}
      {viewReportDrawerOpen && (
        <ViewRiskAssessmentReport
          reportDrawerClose={viewReportDrawerClose}
          reportDrawerOpen={viewReportDrawerOpen}
          dataDetail={networkDetail}
          riskReports={riskReports}
          docList={docList}
          filterList={filterList}
          dataFilter={networkFilter}
          getData={getNetworks}
          isMerging={isMerging}
          handleDownloadClick={handleDownloadClick}
          sortedArray={sortedArray}
          countryCode={countryCode}
          type={connectionTypeName}
          uType="Business"
        />
      )}
    </>
  );
};

export default NetworkRiskReviewPendingBusinessDrawer;
