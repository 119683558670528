import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import moment from "moment";
import light from "../../font/Poppins-Light-300.ttf";
import Regular from "../../font/Poppins-Regular-400.ttf";
import Bold from "../../font/Poppins-Bold-700.ttf";
import SemiBold from "../../font/Poppins-SemiBold-600.ttf";
import Medium from "../../font/Poppins-Medium-500.ttf";
import parse from "html-react-parser";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: light,
      fontWeight: "light",
    },
    {
      src: Regular,
      fontWeight: "normal",
    },
    {
      src: Bold,
      fontWeight: "bold",
    },
    {
      src: SemiBold,
      fontWeight: "semibold",
    },
    {
      src: Medium,
      fontWeight: "medium",
    },
  ],
});

const styles = StyleSheet.create({
  marginTop4: {
    marginTop: "4px",
  },
  body: {
    paddingTop: 65,
    paddingBottom: 65,
    paddingLeft: 65,
    paddingRight: 65,
    paddingHorizontal: 65,
    fontWeight: 100,
  },
  //   title: {
  //     fontSize: 24,
  //     textAlign: "center",
  //     // fontFamily: "Nunito Sans",
  //   },
  text: {
    // margin: 12,
    fontSize: 11,
    // textAlign: "justify",
    // fontFamily: "Nunito Sans",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Poppins",
  },
  page: {
    color: "#383838",
    lineHeight: 1.3,
    // fontWeight: 100,
    // fontFamily:'Nunito Sans',
    letterSpacing: "0.5px",
  },
  text1: {
    fontSize: 11,
    // fontWeight: "bold",
    bottom: 0,
  },
  mb: {
    // paddingTop: 10,
    marginBottom: 15,
    display: "flex",
    flexDirection: "column",
  },
  p: {
    fontSize: 11,
    display: "flex",
    gap: 1,
    paddingTop: "3px",
    bottom: 0,
    textAlign: "justify",
  },
  text13: {
    fontSize: 11,
    textAlign: "justify",
  },
  semibold: {
    // fontWeight: "semibold",
  },
  semiboldfont: {
    fontWeight: "semibold",
    fontFamily: "Poppins",
  },
  boldfont: {
    fontWeight: "bold",
    fontFamily: "Poppins",
  },
  userdetail: {
    fontSize: 11,
    // paddingTop: 1,
    // fontWeight: "bold",
  },
  userDetailTitle: {
    fontSize: 11,
    // paddingTop: 2,
  },
  last: {
    fontSize: 11,
    paddingBottom: 15,
  },
  doc: {
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 15,
  },
  pdfinfo: {
    fontSize: 11,
  },
  number: {
    // marginHorizontal: 2,
    // marginVertical:5
    fontSize: 12,
    display: "flex",
    alignItems: "flex-start",
    gap: 4,
    justifyContent: "flex-start",
  },
});

const stylesAi = StyleSheet.create({
  section: {
    marginBottom: 16,
  },
  paragraph: {
    fontSize: 11,
    marginBottom: 10,
    fontFamily: "Poppins",
    fontWeight: "light",
  },
  hTag: {
    fontFamily: "Poppins",
    fontWeight: "medium",
  },

  listItem: {
    fontSize: 10,
    marginLeft: 15,
    marginBottom: 4,
    fontFamily: "Poppins",
    fontWeight: "normal",
  },
  orderedListItem: {
    fontSize: 10,
    marginLeft: 15,
    marginBottom: 4,
    fontFamily: "Poppins",
    fontWeight: "normal",
  },
  italic: {
    fontStyle: "italic",
  },
  underline: {
    textDecoration: "underline",
  },
  table: {
    marginBottom: 16,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    flex: 1,
    padding: 4,
    borderWidth: 1,
  },
  image: {
    width: 100,
    height: 100,
  },
});

const NetworkRiskPDFFile = ({
  networkDetail,
  userinfo,
  countryCode,
  uType,
  docList,
  data,
}) => {
  const parsedHtml = (htmlString) => {
    return parse(htmlString, {
      replace: (domNode) => mapHtmlToReactPDF(domNode),
    });
  };

  const mapHtmlToReactPDF = (node) => {
    if (!node) return null;

    if (node.type === "tag") {
      const { name, children, attribs } = node;

      switch (name) {
        case "div":
          return (
            <View style={{ ...stylesAi.section }}>
              {children.map(mapHtmlToReactPDF)}
            </View>
          );
        case "p":
          return (
            <Text style={{ ...stylesAi.paragraph }}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
          return (
            <Text
              style={{
                ...stylesAi.paragraph,
                ...stylesAi.hTag,
              }}
            >
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "ul":
          return (
            <View style={{ ...stylesAi.section }}>
              {children.map(mapHtmlToReactPDF)}
            </View>
          );
        case "ol":
          return (
            <View style={{ ...stylesAi.section }}>
              {children.map((child, index) =>
                mapHtmlToReactPDF({
                  ...child,
                  attribs: { ...child.attribs, olIndex: index + 1 },
                })
              )}
            </View>
          );
        case "li":
          if (attribs?.olIndex) {
            return (
              <Text style={{ ...stylesAi.orderedListItem }}>
                {attribs.olIndex}. {children.map(mapHtmlToReactPDF)}
              </Text>
            );
          }
          return (
            <Text style={{ ...stylesAi.listItem }}>
              &#x2022;{`\u00A0`}
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "table":
          return (
            <View style={stylesAi.table}>
              {children.map(mapHtmlToReactPDF)}
            </View>
          );
        case "thead":
        case "tbody":
        case "tfoot":
          return <View>{children.map(mapHtmlToReactPDF)}</View>;
        case "tr":
          return (
            <View style={stylesAi.tableRow}>
              {children.map(mapHtmlToReactPDF)}
            </View>
          );
        case "th":
        case "td":
          return (
            <Text style={stylesAi.tableCell}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "span":
        case "strong":
        case "b":
          return (
            <Text style={{ ...styles.semiboldfont }}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "em":
        case "i":
          return (
            <Text style={{ ...stylesAi.italic }}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "s":
          return (
            <Text style={{ textDecoration: "line-through" }}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "u":
          return (
            <Text style={{ ...stylesAi.underline }}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "a":
          return (
            <Text
              style={[
                stylesAi.paragraph,
                { color: "blue", textDecoration: "underline" },
              ]}
              onPress={() => console.log("Link clicked:", attribs.href)}
            >
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "sub":
          return (
            <Text style={{ fontSize: 8, lineHeight: 10 }}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "sup":
          return (
            <Text style={{ fontSize: 8, lineHeight: 10 }}>
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        case "img":
          return (
            <Image
              source={{ uri: attribs.src }}
              style={{ ...stylesAi.image }}
              alt={attribs.alt}
            />
          );
        case "svg":
          return (
            <Text style={stylesAi.paragraph}>
              [SVG content is not natively supported in React PDF.]
            </Text>
          );
        case "br":
          return <Text>{"\n"}</Text>;
        case "hr":
          return <View style={{ borderBottomWidth: 1, marginVertical: 10 }} />;
        case "pre":
          return (
            <Text
              style={{
                ...stylesAi.paragraph,
                fontFamily: "monospace",
                backgroundColor: "#f4f4f4",
                padding: 8,
              }}
            >
              {children.map(mapHtmlToReactPDF)}
            </Text>
          );
        default:
          return <Text>{children.map(mapHtmlToReactPDF)}</Text>;
      }
    } else if (node.type === "text") {
      return node.data;
    }

    return null;
  };

  return (
    <Document>
      <Page size="A4" style={{ ...styles.body, ...styles.page }}>
        <Text style={styles.header}>
          Risk Assessment Report
          {"\n"}
        </Text>

        {/* Risk assessment on:  */}
        <Text style={styles.last}>
          <Text style={{ ...styles.boldfont, ...styles.userdetail }}>
            {networkDetail &&
            networkDetail?.user_id?.company?.status === "passed"
              ? networkDetail && networkDetail?.user_id?.company?.name
              : networkDetail &&
                networkDetail?.user_id?.fname +
                  " " +
                  networkDetail?.user_id?.lname}
            {"\n"}
          </Text>

          <Text style={styles.userdetail}>
            {networkDetail?.user_id?.company?.status === "passed"
              ? networkDetail &&
                networkDetail?.user_id?.company?.addr?.street_address
              : networkDetail &&
                networkDetail?.user_id?.amlReport?.appData?.info?.addresses[0]
                  ?.street}
            {"\n"}
          </Text>

          <Text style={styles.userdetail}>
            {networkDetail?.user_id?.company?.status === "passed"
              ? (networkDetail &&
                  networkDetail?.user_id?.company?.addr?.postal_code) +
                " " +
                (networkDetail &&
                  networkDetail?.user_id?.company?.addr?.locality) +
                " " +
                (networkDetail &&
                  networkDetail?.user_id?.company?.addr?.country)
              : (networkDetail &&
                  networkDetail?.user_id?.amlReport?.appData?.info?.addresses[0]
                    ?.postCode) +
                " " +
                (networkDetail &&
                  networkDetail?.user_id?.amlReport?.appData?.info?.addresses[0]
                    ?.town) +
                " " +
                (networkDetail &&
                  networkDetail?.user_id?.amlReport?.appData?.info?.addresses[0]
                    ?.country)}
            {"\n"}
          </Text>
        </Text>

        {/* Contact detail  */}
        <Text style={styles.last}>
          <Text style={{ ...styles.semiboldfont, ...styles.userDetailTitle }}>
            Contact: {"\n"}
          </Text>
          {networkDetail &&
            networkDetail?.user_id?.company?.status === "passed" && (
              <Text style={styles.userdetail}>
                {networkDetail &&
                  networkDetail?.user_id?.fname +
                    " " +
                    networkDetail?.user_id?.lname}
                {"\n"}
              </Text>
            )}
          <Text style={styles.userdetail}>
            {countryCode && "+" + countryCode + " " + " "}
            {networkDetail?.user_id?.phone}
            {"\n"}
          </Text>
          <Text style={styles.userdetail}>
            {networkDetail && networkDetail?.user_id?.email}
            {"\n"}
          </Text>
        </Text>

        <Text style={{ ...styles.last, ...styles.semiboldfont }}>
          Last updated on:{" "}
          {moment
            .unix(networkDetail?.updated_risk_time / 1000)
            .format("DD MMM YYYY")}
          {"\n"}
        </Text>

        {/* comment list detail  */}
        {data.map((item, index) => (
          <View style={styles.mb} key={index}>
            <Text style={styles.text1}>
              {data?.length === index + 1 ? "Added" : "Updated"} on:{" "}
              {moment.unix(item?.createdAt / 1000).format("DD MMM YYYY")}
              {"\n"}
            </Text>
            {item?.risk_types[6]?.addedby_present && (
              <Text style={styles.p}>
                Added By:
                {item?.risk_types[6]?.addedby_present && (
                  <Text style={styles.text13}>
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[6]?.addedby_present}{" "}
                    </Text>
                  </Text>
                )}
                {"\n"}
              </Text>
            )}
            <Text style={styles.p}>
              {uType === "Business" ? "Territorial Risk:" : "Country:"}
              {item?.risk_types[3]?.region_prev !== "" &&
                item?.risk_types[3]?.region_present !==
                  item?.risk_types[3]?.region_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[3]?.region_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[3]?.region_prev === "Medium risk" &&
                      item?.risk_types[3]?.region_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[3]?.region_prev === "High risk" &&
                          item?.risk_types[3]?.region_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[3]?.region_prev === "High risk" &&
                          item?.risk_types[3]?.region_present === "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[3]?.region_present}{" "}
              </Text>
              {"\n"}
            </Text>
            <Text style={styles.p}>
              {uType === "Business" ? "Activity Risk:" : "Activity/Funds"}
              {item?.risk_types[2]?.activityfunds_prev !== "" &&
                item?.risk_types[2]?.activityfunds_present !==
                  item?.risk_types[2]?.activityfunds_prev && (
                  <Text style={styles.text13}>
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[2]?.activityfunds_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[2]?.activityfunds_prev ===
                        "Medium risk" &&
                      item?.risk_types[2]?.activityfunds_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[2]?.activityfunds_prev ===
                            "High risk" &&
                          item?.risk_types[2]?.activityfunds_present ===
                            "Low risk"
                        ? "decreased"
                        : item?.risk_types[2]?.activityfunds_prev ===
                            "High risk" &&
                          item?.risk_types[2]?.activityfunds_present ===
                            "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[2]?.activityfunds_present}{" "}
              </Text>
              {"\n"}
            </Text>
            {"sanctions_screening_present" in (item?.risk_types?.[0] || {}) && (
              <Text style={styles.p}>
                Sanctions Screening:
                {item?.risk_types[0]?.sanctions_screening_prev !== "" &&
                  item?.risk_types[0]?.sanctions_screening_present !==
                    item?.risk_types[0]?.sanctions_screening_prev && (
                    <Text style={styles.text13}>
                      <Text style={styles.semibold}>
                        {" "}
                        {item?.risk_types[0]?.sanctions_screening_prev}{" "}
                      </Text>
                      <Text>
                        {item?.risk_types[0]?.sanctions_screening_prev ===
                          "Medium risk" &&
                        item?.risk_types[0]?.sanctions_screening_present ===
                          "Low risk"
                          ? "decreased"
                          : item?.risk_types[0]?.sanctions_screening_prev ===
                              "High risk" &&
                            item?.risk_types[0]?.sanctions_screening_present ===
                              "Low risk"
                          ? "decreased"
                          : item?.risk_types[0]?.sanctions_screening_prev ===
                              "High risk" &&
                            item?.risk_types[0]?.sanctions_screening_present ===
                              "Medium risk"
                          ? "decreased"
                          : item?.risk_types[0]?.sanctions_screening_present ===
                            ""
                          ? ""
                          : " increased"}{" "}
                        {item?.risk_types[0]?.sanctions_screening_present !==
                          "" && "to"}
                      </Text>
                    </Text>
                  )}
                <Text style={styles.semibold}>
                  {" "}
                  {item?.risk_types[0]?.sanctions_screening_present === "" &&
                  item?.risk_types[0]?.sanctions_screening_prev === ""
                    ? ""
                    : item?.risk_types[0]?.sanctions_screening_present}{" "}
                </Text>
                {"\n"}
              </Text>
            )}
            <Text style={styles.p}>
              {uType === "Business"
                ? "Directors/UBO's Screening:"
                : "Documents:"}
              {item?.risk_types[5]?.document_prev !== "" &&
                item?.risk_types[5]?.document_present !==
                  item?.risk_types[5]?.document_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[5]?.document_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[5]?.document_prev === "Medium risk" &&
                      item?.risk_types[5]?.document_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[5]?.document_prev === "High risk" &&
                          item?.risk_types[5]?.document_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[5]?.document_prev === "High risk" &&
                          item?.risk_types[5]?.document_present ===
                            "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[5]?.document_present}{" "}
              </Text>
              {"\n"}
            </Text>
            <Text style={styles.p}>
              {uType === "Business"
                ? "Website/Online Activity:"
                : "Adverse Media:"}
              {item?.risk_types[1]?.adversemedia_prev !== "" &&
                item?.risk_types[1]?.adversemedia_present !==
                  item?.risk_types[1]?.adversemedia_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[1]?.adversemedia_prev}{" "}
                    </Text>
                    <Text>
                      {item?.risk_types[1]?.adversemedia_prev ===
                        "Medium risk" &&
                      item?.risk_types[1]?.adversemedia_present === "Low risk"
                        ? "decreased"
                        : item?.risk_types[1]?.adversemedia_prev ===
                            "High risk" &&
                          item?.risk_types[1]?.adversemedia_present ===
                            "Low risk"
                        ? "decreased"
                        : item?.risk_types[1]?.adversemedia_prev ===
                            "High risk" &&
                          item?.risk_types[1]?.adversemedia_present ===
                            "Medium risk"
                        ? "decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[1]?.adversemedia_present}{" "}
              </Text>
              {"\n"}
            </Text>
            <Text style={styles.p}>
              {uType === "Business"
                ? "Overall Risk Classification:"
                : "Overall risk rating:"}
              {item?.risk_types[4]?.overall_risk_prev !== "" &&
                item?.risk_types[4]?.overall_risk_present !==
                  item?.risk_types[4]?.overall_risk_prev && (
                  <Text style={styles.text13}>
                    {" "}
                    <Text style={styles.semibold}>
                      {" "}
                      {item?.risk_types[4]?.overall_risk_prev} risk
                    </Text>
                    <Text>
                      {item?.risk_types[4]?.overall_risk_prev === "Medium" &&
                      item?.risk_types[4]?.overall_risk_present === "Low"
                        ? " decreased"
                        : item?.risk_types[4]?.overall_risk_prev === "High" &&
                          item?.risk_types[4]?.overall_risk_present === "Low"
                        ? " decreased"
                        : item?.risk_types[4]?.overall_risk_prev === "High" &&
                          item?.risk_types[4]?.overall_risk_present === "Medium"
                        ? " decreased"
                        : " increased"}{" "}
                      to
                    </Text>{" "}
                  </Text>
                )}
              <Text style={styles.semibold}>
                {" "}
                {item?.risk_types[4]?.overall_risk_present} risk
              </Text>
              {"\n"}
            </Text>
            <Text style={{ ...styles.text1, ...styles.marginTop4 }}>
              Comments:{" "}
              {!item?.ai_comment && (
                <Text style={styles.text}>
                  {item.description}
                  {"\n"}
                </Text>
              )}
            </Text>{" "}
            {item?.ai_comment && parsedHtml(item?.description)}
          </View>
        ))}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default NetworkRiskPDFFile;
