export const toolTipsData = {
  Region:
    "Assesses any heightened risk due to the country or region involved (e.g., high-corruption or sanctioned jurisdictions).",
  "Activity/Funds":
    "Reflects the level of risk based on the nature and sector of the business (e.g., financial services, cryptocurrency, etc.).",
  "Sanctions Screening":
    "Checks whether the entity or territory appears on any sanctions or watch lists.",
  Documents:
    "Evaluates the background of directors and ultimate beneficial owners for any potential red flags or illicit involvement. This includes checking for personal sanctions, Politically Exposed Persons (PEPs), and adverse media coverage.",
  "Adverse Media":
    "Considers the risk profile of the entity’s online presence, such as questionable content or lack of transparency.",
  "Overall risk rating":
    "Provides an aggregated rating that combines all risk factors to indicate the entity’s overall compliance risk.",
};
