import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getConnection = async (id) => {
  const response = await axios.get(
    baseUrl + `/api/connect/get?connectionId=${id}`
  );
  return response.data;
};

export const connectionUpdate = async (payload) => {
  const response = await axios.post(baseUrl + `/api/connect/update`, payload);
  return response.data;
};

export const sendConnectionMail = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/user/expiredDocRequest`,
    payload
  );
  return response.data;
};

export const createNewLink = async (payload) => {
  const response = await axios.post(
    baseUrl + "/link/private/create-self-onboard",
    payload
  );
  return response.data;
};

export const requestIdConnection = async (payload) => {
  const response = await axios.post(baseUrl + "/api/req-new-id-doc", payload);
  return response.data;
};

export const askAiAgentComment = async (payload, abortSignal) => {
  const response = await axios.post(baseUrl + "/api/ai-comment", payload, {
    signal: abortSignal,
  });
  return response.data;
};
