import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import "./rte.css";

const RTE = forwardRef(
  (
    {
      innerHTML,
      isDisabled = false,
      showToolbar = true,
      height = "100%",
      placeholder = "Enter mail body...",
      setShowDiscard,
    },
    ref
  ) => {
    const [content, setContent] = useState();

    useEffect(() => {
      setContent(innerHTML);
    }, [innerHTML]);

    useImperativeHandle(ref, () => ({
      content,
      setContent: (newContent) => setContent(newContent),
    }));

    return (
      <SunEditor
        disable={isDisabled}
        hideToolbar={!showToolbar}
        setOptions={{
          buttonList: [["bold", "underline", "italic"]],
          defaultStyle: "font-family: Nunito Sans; font-size: 16px",
        }}
        placeholder={placeholder}
        setContents={content}
        onChange={(newContent) => setContent(newContent)}
        onInput={() => setShowDiscard?.(true)}
        height={height}
      />
    );
  }
);

export default RTE;
