import React, { useEffect, useState } from "react";
import { Drawer, message, Spin } from "antd";
import { getPdfData } from "../../../../services/admin-ai-viewer";
import AdminAiViewerDrawer from "./adminAiViewerDrawer";
import CustomerAiViewerDrawer from "./customerAiViewerDrawer";
import "./AiReportViewerDrawer.css";

const AiViewerDrawer = ({
  tokenId,
  type,
  apiId,
  fetchConnectionData,
  companyName,
  aiViewerDrawerOpen,
  aiViewerDrawerClose,
  getOnBoardingApi,
  customerUi = true,
}) => {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [responseData, setResponseData] = useState();
  const [uiShow, setUiShow] = useState(null);
  const handleClose = () => {
    aiViewerDrawerClose(false);
  };

  useEffect(() => {
    if (!tokenId) {
      return;
    }
    setIsPdfLoading(true);
    getPdfData(tokenId)
      .then((res) => {
        if (res?.error) {
          console.log("res?.error :>> ", res?.error);
          setIsPdfLoading(false);
          return message.error(res?.error?.message);
        }
        if (customerUi) {
          if (res?.userCertificateData?.newGrid) {
            setUiShow(true);
          } else {
            setUiShow(false);
          }
        } else {
          setUiShow(customerUi);
        }
        setResponseData(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPdfLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  return (
    <>
      <Drawer
        push={false}
        placement="right"
        className="AiReportViewerDrawer select-none"
        closable={false}
        onClose={handleClose}
        open={aiViewerDrawerOpen}
      >
        {isPdfLoading ? (
          <Spin className="mt-20 w-full" />
        ) : uiShow ? (
          <CustomerAiViewerDrawer
            tokenId={tokenId}
            type={type}
            apiId={apiId}
            fetchConnectionData={fetchConnectionData}
            companyName={companyName}
            responseData={responseData}
            handleClose={handleClose}
          />
        ) : (
          uiShow === false && (
            <AdminAiViewerDrawer
              handleClose={handleClose}
              tokenId={tokenId}
              companyName={companyName}
              getOnBoardingApi={getOnBoardingApi}
              fetchConnectionData={fetchConnectionData}
              responseData={responseData}
              customerUi={customerUi}
            />
          )
        )}
      </Drawer>
    </>
  );
};

export default AiViewerDrawer;
