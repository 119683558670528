import { Spin, Tooltip } from "antd";
import React from "react";
import AvatarWithTitle from "../../../ui/AvatarWithTitle";
import { MdOutlineEmail } from "react-icons/md";
import moment from "moment";
import { FiInfo } from "react-icons/fi";
const RiskAssessmentDetailsStep = ({
  data,
  removeConnection,
  connectionDetail,
  connectCountryCode,
  isLoading,
  connectionActions,
  adminDetails,
  requestIdApi,
  sendMail,
  handleAction,
  setViewReportDrawerOpen,
  setAddReportDrawerOpen,
  riskAssessmentSteps,
}) => {
  const checkExpiry = (date) => {
    const cddDate = new Date(date);
    const currentDate = new Date();

    if (currentDate > cddDate) {
      return true;
    } else {
      return false;
    }
  };

  const utypeName =
    adminDetails?._id === connectionDetail?.receiver?._id
      ? connectionDetail?.sender?.utype === "BUSINESS"
      : connectionDetail?.receiver?.utype === "BUSINESS";

  return (
    <div className="w-full flex flex-col gap-5 pb-2">
      {/* personal information */}
      <div className="bg-[#fff] py-3 px-5 w-full rounded-xl shadow-md flex flex-col gap-3">
        <div className="lg:gap-2 gap-3 justify-between items-start grid grid-cols-12">
          <div className="flex flex-col gap-3 w-full col-span-8">
            <AvatarWithTitle
              firstName={data?.fname}
              lastName={data?.lname}
              subTitle={
                (data && data?.company?.status === "passed"
                  ? data?.company?.addr?.street_address
                  : data && data?.location?.street) +
                ", " +
                (data && data?.company?.status === "passed"
                  ? data?.company?.addr?.postal_code +
                    " " +
                    data?.company?.addr?.locality +
                    " " +
                    data?.company?.addr?.country
                  : data?.location?.postCode +
                    " " +
                    data?.location?.town +
                    " " +
                    data?.location?.country)
              }
            />
          </div>

          <button
            className="text-[#2C6ED2] font-bold text-sm whitespace-nowrap col-span-4 flex justify-end"
            onClick={() => removeConnection(connectionDetail)}
          >
            Remove Connection
          </button>
        </div>
        <div className="ml-12 flex flex-col gap-1">
          <div className="flex gap-3 justify-between flex-wrap items-center">
            <div className="text-[#000] leading-none font-bold">
              {data && data?.company?.status === "passed"
                ? data?.company?.name
                : data?.fname + " " + data?.lname}
            </div>

            <p className="flex gap-1 items-center">
              <MdOutlineEmail />
              <span className="text-[#000] font-normal text-sm">
                {data?.email}
              </span>
            </p>
          </div>
          <div className="flex gap-3 justify-between flex-wrap items-center">
            <span className="font-normal text-sm truncate flex gap-1 items-center">
              Connection since:
              <span className="text-[#000] font-bold leading-none">
                {moment.unix(data?.createdAt / 1000).format("DD MMM YYYY")}
              </span>
            </span>

            <span className="text-[#000] font-bold text-sm">
              {connectCountryCode && "+" + connectCountryCode + "   "}
              {data?.phone}
            </span>
          </div>
        </div>
      </div>

      {/* manage connection */}
      <Spin spinning={isLoading}>
        <div className="w-full flex flex-col gap-5 h-full">
          <div className="bg-[#fff] p-5 w-full flex flex-col gap-3 rounded-xl shadow-md">
            {connectionActions?.map((item, index) => {
              const content =
                item.fieldName1 === "Scope"
                  ? "<div class='font-thin'>If this team member temporarily is not processing, storing, or accessing any data that impacts your AML program, you can mark them as not-in-scope.</div>"
                  : "";
              return (
                <div key={index} className="flex justify-between gap-5">
                  <p className="text-[#000] font-bold text-base flex gap-2 items-center">
                    {item?.fieldName1}
                    {item?.fieldName1 === "Scope" && (
                      <span className="text-[#00000096]">
                        <Tooltip
                          color="black"
                          placement="right"
                          overlayStyle={{
                            maxWidth: "400px",
                            fontWeight: "400",
                          }}
                          title={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content,
                              }}
                            />
                          }
                        >
                          <FiInfo />
                        </Tooltip>
                      </span>
                    )}
                  </p>
                  <div className="flex gap-5 items-center">
                    {!isLoading ? item?.fieldDetail1 : ""}
                    <button
                      className="text-[#2C6ED2] font-bold text-sm"
                      onClick={() => {
                        handleAction(item);
                      }}
                    >
                      {item?.fieldName1 === "Scope" ? "Change" : "Edit"}
                    </button>
                  </div>
                </div>
              );
            })}

            {checkExpiry(
              adminDetails?._id === connectionDetail?.sender?._id
                ? connectionDetail?.receiver?.cdd?.validTill
                : connectionDetail?.sender?.cdd?.validTill
            ) && (
              <div className="flex justify-between gap-5 w-full">
                <div className="text-[#000] items-center w-full">
                  <span className="font-bold text-base flex justify-start flex-wrap">
                    CDD Report Expiration Date:
                    <span className="text-danger ml-1">
                      {adminDetails?._id === connectionDetail?.sender?._id
                        ? connectionDetail?.receiver?.cdd?.validTill
                        : connectionDetail?.sender?.cdd?.validTill}
                    </span>
                  </span>
                </div>
                <button
                  className="text-[#2C6ED2] font-bold text-sm whitespace-nowrap"
                  onClick={() => sendMail()}
                >
                  Request Update
                </button>
              </div>
            )}

            {checkExpiry(
              adminDetails?._id === connectionDetail?.sender?._id
                ? moment(
                    connectionDetail?.receiver?.amlReport?.appData?.info?.idDocs?.find(
                      (a) => a.idDocType && a.validUntil
                    )?.validUntil
                  ).format("DD MMM YYYY")
                : moment(
                    connectionDetail?.sender?.amlReport?.appData?.info?.idDocs?.find(
                      (a) => a.idDocType && a.validUntil
                    )?.validUntil
                  ).format("DD MMM YYYY")
            ) && (
              <div className="flex justify-between gap-5 w-full">
                <div className="text-[#000] items-center w-full">
                  <span className="font-bold text-base flex justify-start flex-wrap items-center h-full">
                    ID Expiration date:
                    <span className="text-danger ml-1">
                      {adminDetails?._id === connectionDetail?.sender?._id
                        ? moment(
                            connectionDetail?.receiver?.amlReport?.appData?.info?.idDocs?.find(
                              (a) => a.idDocType && a.validUntil
                            )?.validUntil
                          ).format("DD MMM YYYY")
                        : moment(
                            connectionDetail?.sender?.amlReport?.appData?.info?.idDocs?.find(
                              (a) => a.idDocType && a.validUntil
                            )?.validUntil
                          ).format("DD MMM YYYY")}
                    </span>
                  </span>
                </div>
                <button
                  className="text-[#2C6ED2] font-bold text-sm whitespace-nowrap"
                  onClick={requestIdApi}
                >
                  Request New ID
                </button>
              </div>
            )}
          </div>
        </div>
      </Spin>

      {/* add supporting documents */}
      <div className="bg-[#fff] p-5 w-full flex flex-col gap-3 rounded-xl shadow-md">
        <div className="flex justify-between gap-5">
          <p className="text-[#000] font-bold flex gap-2 items-center text-[15px]">
            Add Supporting Documents
          </p>

          <button
            onClick={() => setAddReportDrawerOpen(true)}
            className="text-[#2C6ED2] font-bold text-sm"
          >
            Upload
          </button>
        </div>
      </div>

      {/* reports & supporting documents */}
      <div className="bg-[#fff] p-5 w-full flex flex-col gap-3 rounded-xl shadow-md">
        <div className="flex justify-between gap-5">
          <p className="text-[#000] font-bold flex gap-2 items-center text-[15px]">
            Reports & Supporting Documents
          </p>

          <button
            onClick={() => setViewReportDrawerOpen(true)}
            className="text-[#2C6ED2] font-bold text-sm"
          >
            View
          </button>
        </div>
      </div>

      {utypeName && (
        <>
          <p className="text-xl font-bold mx-5 mt-4 mb-0">
            Risk Assessment Steps
          </p>
          {/* risk assessment steps */}
          {riskAssessmentSteps?.map((item, index) => (
            <Spin spinning={item?.loadingName} key={index}>
              <div
                key={index}
                className="bg-[#fff] p-5 w-full flex gap-5 rounded-xl shadow-md justify-between items-center"
              >
                <div className="flex gap-1 items-center">
                  <p className="text-[#000] font-bold flex gap-1 items-center text-[15px]">
                    Step {item?.key}:
                  </p>
                  <p className="font-normal truncate text-[15px]">
                    {item?.label}
                  </p>
                </div>

                <button
                  onClick={item?.click}
                  className="text-[#2C6ED2] font-bold text-sm"
                >
                  {item?.buttonName}
                </button>
              </div>
            </Spin>
          ))}{" "}
        </>
      )}
    </div>
  );
};

export default RiskAssessmentDetailsStep;
